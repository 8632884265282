// eslint-disable-next-line
class carCenterCostComponent extends Component {

    static name() {
        return "carCenterCostList";
    }

    static componentName() {
        return "carCenterCostList";
    }
    getData() {
        return this.data;
    }

    data() {
        return {
            selectedCode:null,
            centerCostList:[],
            showList:[],
            filterable: ["Code", "Name"],
            columns: ['Code', 'Name'],
            options: {
                filterable: ["Code", "Name"],
                translateFunction: this.tr,
                perPage: 10,
                pagination: {
                    chunk: 10,
                },
                headings: {
                    'Code': this.tr('Code'),
                    'Name': this.tr('Name')
                },
                texts:{
                    filter:this.tr('Search') + ':',
                    limit:'',
                    filterPlaceholder:this.tr('Write a value'),
                    noResults:this.tr('No data were found'),
                    loading:this.tr('Loading') + '...'
                }
            },
            uniqueKey: "Code",
            selectedNode:null
        };
    }
    mounted() {
        return async function () {
            let self = this;
            // eslint-disable-next-line
            axios.get(`/ecommerce/api/getCenterCost`).then((response)=>{
                self.centerCostList = response.data.centerCost;
                self.$store.dispatch('centerCostRequire',self.centerCostList.length > 1);
                if(self.centerCostList.length == 1) {
                    self.selectEvent(self.centerCostList[0]);
                }
                else{
                    if(self.centerCostList.length == 0)
                        this.emitEvent('emptycentercost',true);
                    else{
                        if(self.$store.getters.getIsLogin) {
                            for(let cc of self.centerCostList)
                                if(cc.Code == self.$store.state.session.fields.DefaultCenterCost)
                                    self.selectEvent(cc);
                        }
                    }
                }
            });
        };
    }
    getMethods() {
        return {
            isSelected:this.isSelected,
            selectEvent:this.selectEvent,
            changeData:this.changeData,
        };
    }

    isSelected(code){
        return this.selectedCode == code;
    }

    selectEvent(ccObjc){
        this.selectedCode = ccObjc.Code;
        this.$store.dispatch('updateOrderCenterCost',ccObjc);
        this.emitEvent('selectcentercost',ccObjc);
    }

    changeData(obj){
        this.selectEvent(obj.row);
        let rowHtmlNode = obj.event.target.parentElement;
        if(rowHtmlNode.classList.contains("selected")){
            rowHtmlNode.className = "";
            this.selectedNode = null;
        } else {
            this.selectedNode = rowHtmlNode;
            rowHtmlNode.classList.add("selected");
            this.$store.dispatch('showNotificacion',{title: '', message: this.tr("Cost Center") + " " + obj.row.Code,type: 'success'});
        }
    }

    getTemplate() {
        return `<div class="col-12 car-center-cost-list" v-if="centerCostList.length > 0" >
                <div class="col-12">
                    <div class="bordered mb-0 mt-3">
                        <div class="row m-0 align-items-center">
                            <h6 class="col-12">
                                <i class="fas fa-address-book fa-2x mr-3"></i>
                                {{tr('Select a Customer Cost Center')}}
                            </h6>
                        </div>
                        <v-client-table class="custom-vue-table" @row-click="changeData"  :columns=columns :data="centerCostList" :options="options"></v-client-table>
                    </div>
                </div>
            </div>`;
    }
}

carCenterCostComponent.registerComponent();